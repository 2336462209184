import style from './footer.module.css'

const Footer =()=>{
  return(
    <footer >
    <div>
     <p>© 2019-2022 Fields Manager</p>
</div>
</footer>
  )
}

export default Footer
