import React, { useState } from "react";
import styles from "./videolink.module.css";
import axios from "axios";
import { environment } from "../../environment";
import { useLocation } from "react-router";
import { useEffect } from "react";
import StreamIcon from "../Icons/StreamIcon";
import AdsIcon from "../Icons/AdsIcon";
import BackupIcon from "../Icons/BackupIcon";
import ReplayIcon from "../Icons/ReplayIcon";
import ReturnIcon from "../Icons/Return";
import Modal from "react-modal";

const VideoLink = () => {
  const search = useLocation().search;
  const uid = new URLSearchParams(search).get("user_id");
  const slotId = new URLSearchParams(search).get("slot_id");
  const tag = new URLSearchParams(search).get("tag");
  const streamUrl = environment.streamUrl;
  const [play, setPlay] = useState({ status: "On" });
  const [ApiResponse, setApiResponse] = useState({ status: "Idle" });
  const [scoreCard, setScoreCard] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isOpenScore, setOpenScore] = useState(false);

  function handleClose() {
    setOpen(!isOpen);
  }
  function handleCloseScore() {
    setOpenScore(!isOpenScore);
  }

  useEffect(() => {
    axios
      .get(streamUrl + "/livestream?user_id=" + uid)
      .then((res) => setPlay(res.data));
  }, []);

  const url = play;
  console.log("api", url);

  function radioClick() {
    console.log("scoreCard", scoreCard);
    if (scoreCard === true) {
      setScoreCard(false);
    } else {
      console.log("inside else");
      setScoreCard(true);
      console.log("in adsPlayHandlerAPI", scoreCard);
    }
    console.log("before adsPlayHandlerAPI", scoreCard);
    adsPlayHandlerAPI(streamUrl, "scoreCard", scoreCard);
  }

  const replayClick = () => {
    let today = new Date();
    let timestamp = today.toISOString().split("T")[1].split(".")[0];
    axios
      .get(streamUrl + "/replay?user_id=" + uid + "&timestamp=" + timestamp)
      .then((res) => {
        console.log("response inside replayClick" + res.data.body);
      });
  };
  const rekognitionReplayClick = () => {
    axios.get(streamUrl + "/rekognition-replay?user_id=" + uid).then((res) => {
      console.log("response inside rekognitionReplayClick" + res.data.body);
    });
  };

  async function adsPlayHandlerAPI(link, name, scoreCardVal, card) {
    console.log("init  streamPlayHandlerAPI");
    try {
      setApiResponse({ status: "InProgress" });
      // let response = {a:"b"}
      let response = await axios.get(link, {
        params: {
          user_id: uid,
          slot_id: slotId,
          action: name,
          scoreCard: scoreCardVal,
          act: card,
        },
      });
      setApiResponse(response);
    } catch (e) {
      setApiResponse({ status: "Failed" });
    }
  }

  return (
    <div className="container">
      <div className="d-flex bd-highlight">
        <div className="container">
          <div className="d-flex flex-row justify-content-center">
            <div className={styles.videoWrapper}>
              <iframe
                src={url}
                title="video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="iframe"
              ></iframe>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <span style={{ color: "#b6860d" }}>
              LIVE STREAMING CONTROL PANEL
            </span>
          </div>
          <br />
          {/* button section starts */}
          <div className="d-flex align-items-center justify-content-center">
            <button name="graphics" type="button" onClick={handleClose}>
              <span>
                <StreamIcon />
                Graphics
              </span>
            </button>
            <Modal
              id="graphic-modal"
              isOpen={isOpen}
              onRequestClose={handleClose}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  borderRadius: "0px",
                },
              }}
            >
              <table>
                <tr>
                  <td>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "four"
                        )
                      }
                    >
                      <span>It's a 4</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "six"
                        )
                      }
                    >
                      <span>It's a 6</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "single"
                        )
                      }
                    >
                      <span>It's a single 1</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "catch-out"
                        )
                      }
                    >
                      <span>Caught Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "bowled"
                        )
                      }
                    >
                      <span>Clean Bowled</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "decision-pending"
                        )
                      }
                    >
                      <span>Decision Pending</span>
                    </button>
                  </td>
                  <td colSpan={2}>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "free-hit"
                        )
                      }
                    >
                      <span>Free Hit</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "run-out"
                        )
                      }
                    >
                      <span>Run Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "scene-on"
                        )
                      }
                    >
                      <span>Scene On</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "out"
                        )
                      }
                    >
                      <span>That's Out</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "watch"
                        )
                      }
                    >
                      <span>Watch</span>
                    </button>
                    <button
                      onClick={() =>
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "T20"
                        )
                      }
                    >
                      <span>T20</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td width="90px"></td>
                  <td height="60px">
                    <p onClick={handleClose}>
                      <ReturnIcon />
                    </p>
                  </td>
                </tr>
              </table>
            </Modal>

            <button name="score" type="button" onClick={handleCloseScore}>
              <span>
                <StreamIcon />
                Score
              </span>
            </button>
            <Modal
              id="graphic-modal"
              isOpen={isOpenScore}
              onRequestClose={handleCloseScore}
              style={{
                overlay: {
                  position: "static",
                  backgroundColor: "rgba(0,0,0,0)",
                },
                content: {
                  background: "rgba(0,0,0,0)",
                  border: "0px",
                  position: "absolute",
                  top: "27%",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  borderRadius: "0px",
                },
              }}
            >
              <table>
                <tr>
                  <td height="100px"></td>
                </tr>
                <tr>
                  <td>
                    <button
                      name="score-card"
                      onClick={() => {
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "score_card"
                        );
                        handleCloseScore();
                      }}
                      type="button"
                    >
                      <StreamIcon />
                      <span>Score Card</span>
                    </button>
                  </td>
                  <td>
                    <button
                      name="score-card-chauka"
                      onClick={() => {
                        adsPlayHandlerAPI(
                          streamUrl + `/graphic-overlay`,
                          "graphic-overlay",
                          scoreCard,
                          "score_card_chauka"
                        );
                        handleCloseScore();
                      }}
                      type="button"
                    >
                      <StreamIcon />
                      <span>Score - Chauka</span>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td width="90px"></td>
                </tr>
                <tr>
                  <td></td>
                  <td height="60px">
                    <p onClick={handleCloseScore}>
                      <ReturnIcon />
                    </p>
                  </td>
                </tr>
                <tr>
                  <td height="100px"></td>
                </tr>
              </table>
            </Modal>
          </div>
          <div className="d-flex flex-row justify-content-center mx-1">
            <div className="col-md-4 col-lg-4">
              <div className="d-flex justify-content-center ">
                <button
                  name="stream"
                  // onClick={() => setPlay(!play)}
                  onClick={() =>
                    adsPlayHandlerAPI(
                      streamUrl + `/streams`,
                      "stream",
                      scoreCard
                    )
                  }
                  type="button"
                >
                  <StreamIcon />
                  <span>Stream</span>
                </button>
                <button
                  name="ads"
                  onClick={() =>
                    adsPlayHandlerAPI(streamUrl + `/ads`, "ads", scoreCard)
                  }
                  type="button"
                >
                  <AdsIcon />
                  <span>Ads</span>
                </button>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  name="replay"
                  type="button"
                  onClick={() => replayClick()}
                >
                  <ReplayIcon />
                  <span>Replay</span>
                </button>
                <button
                  name="rekognition-replay"
                  type="button"
                  onClick={() => rekognitionReplayClick()}
                >
                  <ReplayIcon />
                  <span>Rekognition Replay</span>
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button
                  name="backup"
                  type="button"
                  onClick={() =>
                    adsPlayHandlerAPI(
                      streamUrl + `/backup`,
                      "backup",
                      scoreCard
                    )
                  }
                >
                  <BackupIcon />
                  <span>Backup</span>
                </button>
                {tag ? (
                  <a href={environment.taggerUrl}>
                    <button>
                      <BackupIcon />
                      <span>Tag Frames</span>
                    </button>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            Status: {ApiResponse.status}
          </div>
          {/* button section  ends */}
          <div className="d-flex justify-content-center m-3">
            <div className="radio-btn">
              <div
                className="radio-btn"
                onClick={() => {
                  radioClick();
                }}
              >
                <input type="checkbox" name="scoreCard" value={scoreCard} />{" "}
                Show score card
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoLink;
