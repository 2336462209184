import "./App.css";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import VideoLink from "./components/Videolink/videolink";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<VideoLink/>}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
